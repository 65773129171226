import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ProfileImage from "asset/images/images/profile-image1.svg";
import EditIcon from "asset/images/images/edit-icon.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import Loader from "ui-component/Loader";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [profileDetails, setProfileDetails] = useState<any>();
  const userId: any = useAppSelector((state) => state?.user?.user);
  const theme = useTheme();
  const handleClick = () => {
    navigate("/updateprofile");
  };

  const getUserDetails = () => {
    setIsLoading(true);
    commonService
      .getServices(`/admin/${userId?._id}`)
      .then((res) => {
        const userData = res?.data?.data[0];
        setProfileDetails(userData);
        setIsLoading(false);
      })
      .catch((error) => {
        Failed(errorMessage(error, "Oops! Something Went Wrong"));
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Box sx={{ padding: "1% 1.5%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="text.primary">Profile</Typography>
        </Breadcrumbs>

        <Card sx={{ marginTop: 4, boxShadow: 1 }}>
          <CardContent sx={{ padding: 0 }}>
            <Box
              sx={{
                paddingY: "20px",
                paddingX: "20px",
                fontWeight: 600,
                fontSize: "20px",
                display: "flex",
                justifyContent: "space-between",
                color: theme.palette.primary.textBtn,
              }}
            >
              Basic details
              <span style={{ display: "flex" }}>
                {" "}
                <img
                  src={EditIcon}
                  alt="Edit Icon"
                  style={{ width: "22px", height: "22px", cursor: "pointer" }}
                  onClick={handleClick}
                />
                <span
                  style={{
                    fontSize: "16px",
                    color: theme.palette.primary.textLink,
                    marginLeft: "15px",
                  }}
                >
                  Edit
                </span>
              </span>
            </Box>
            <Divider />
            <Box
              sx={{
                // backgroundColor: "#0302290A",
                height: 133,
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                padding: "1% 1.5%",
                gap: 2,
              }}
            >
              <Box position="relative">
                {userId?.photo ? (
                  <Box
                    component={"img"}
                    height={80}
                    width={80}
                    src={userId?.photo}
                    alt="Profile Image"
                    sx={{
                      // marginTop: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: "80px",
                      width: "80px",
                      // marginTop: "60px",
                      background: "grey",
                    }}
                  />
                )}
              </Box>
              <Typography variant="h3" color={"#0A1F41"}>
                {profileDetails?.name}
              </Typography>
            </Box>
            <Grid container spacing={3} sx={{ padding: "1% 1.5%" }}>
              <Grid item sm={3}>
                <Typography color={"#6B778C"} sx={{ fontWeight: 500 }}>
                  Email ID
                </Typography>
                <Tooltip title={profileDetails?.email}>
                  <Typography
                    fontWeight={500}
                    fontSize={"16px"}
                    color={"#0A1F41"}
                    marginTop={2}
                    sx={{
                      display: "inline-block", // Ensures the text behaves as an inline block
                      overflow: "hidden", // Hides the overflow text
                      textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                      whiteSpace: "nowrap", // Prevents the text from wrapping
                      verticalAlign: "bottom", //
                      maxWidth: "250px",
                      cursor: "pointer",
                    }}
                  >
                    {profileDetails?.email}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item sm={3}>
                <Typography color={"#6B778C"} sx={{ fontWeight: 500 }}>
                  Mobile number
                </Typography>
                <Typography
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#0A1F41"}
                  marginTop={2}
                >
                  {profileDetails?.country_code + " " + profileDetails?.mobile}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ boxShadow: 1, marginTop: "10px" }}>
          <CardContent sx={{ padding: 0 }}>
            <Box
              sx={{
                paddingY: "20px",
                paddingX: "20px",
                fontWeight: 600,
                fontSize: "20px",
                display: "flex",
                justifyContent: "space-between",
                color: theme.palette.primary.textBtn,
              }}
            >
              Password Settings
              {profileDetails?.password_update_date && (
                <Typography variant="h4" color={"#6B778C"} marginTop={1}>
                  Last updated on :{" "}
                  <span style={{ color: theme.palette.primary.textBtn }}>
                    {new Date(
                      profileDetails?.password_update_date
                    ).toLocaleDateString()}
                  </span>
                </Typography>
              )}
            </Box>
            <Divider />
            <Grid item sm={3} sx={{ paddingLeft: "20px" }}>
              <Typography color={"#6B778C"} sx={{ mt: 3, fontWeight: 500 }}>
                Current password
              </Typography>
              <Typography variant="h3" color={"#0A1F41"} marginTop={2}>
                *******
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default UpdateProfile;
