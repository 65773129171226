import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Tab,
  Button,
  Grid,
  Typography,
  Chip,
  Card,
  CardContent,
  Divider,
  useTheme,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { commonService } from "forms/helpers/common.service";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import deleteAlert from "component/HOC/deleteAlert";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";

const Subscription = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<any>("active");
  const [count, setCount] = useState<any>();
  const [inActivecount, setInactiveCount] = useState<any>();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  let [order, setOrder] = useState<any>("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [subscriptionData, setSubscriptionData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const location = useLocation();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getSubscription();
  }, [value]);

  const getSubscription = () => {
    setLoading(true);
    const params = new URLSearchParams({
      per_page: rowsPerPage.toString(),
      page: (page + 1).toString(),
      sort_order: order,
      sort_by: orderBy,
      is_active: value == "active" ? "true" : "false",
    }).toString();

    commonService
      .getServices(`plan?${params}`)
      .then((res) => {
        console.log(res);
        setSubscriptionData(res?.data?.data?.results);
        if (value == "active") {
          setCount(res?.data?.data?.pagination?.found);
        } else {
          setInactiveCount(res?.data?.data?.pagination?.found);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setLoading(false);
      });
  };

  const handleNavigate = (id: any) => {
    if (location?.state?.name) {
      return;
    } else {
      navigate(`/view_plan`, {
        state: {
          id: id,
        },
      });
    }
  };

  const handlePlan = (id: any) =>
    deleteAlert({
      title: "Subscribe",
      message: "Are you sure?",
      confirmButtonText: "Yes",
      onConfirmed: () => handleSubscribe(id),
    });
  const handleSubscribe = (id: any) => {
    commonService
      ?.patchService(`/admin/plan?employerId=${location?.state?.id}`, {
        plan: id,
      })
      .then((res: any) => {
        console.log(res?.data);
        Success(res?.data?.message);
        navigate(`/view_employer`, {
          state: {
            id: location?.state?.id,
          },
        });
      })
      .catch((err: any) => {
        console.log(err);
        Failed(errorMessage(err, "Oops! Something Went Wrong"));
      });
  };
  return (
    <Box sx={{ padding: " 20px" }}>
      {isLoading && <Loader />}
      <TabContext value={value}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "30%",
              }}
            >
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleTabChange}
              >
                <Tab label={`Active (${count})`} value="active" />
                <Tab label={`Inactive (${inActivecount})`} value="inactive" />
              </TabList>
            </Box>
            <Button onClick={() => navigate(`/add_plan`)}>Add Plan</Button>
          </Box>

          <TabPanel value="active" sx={{ padding: "0px" }}>
            {/* <CandidateList /> */}
          </TabPanel>

          <TabPanel value="inactive" sx={{ padding: "0px" }}>
            {/* Content for recruiter tab */}
          </TabPanel>
        </Box>
      </TabContext>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {subscriptionData.map((data, index) => (
          <Grid item md={3} key={index}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                ":hover": {
                  border: `1px solid ${theme.palette.primary.buttonOrange}`,
                },
                cursor: "pointer",
              }}
              onClick={() => handleNavigate(data?._id)}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={"20px"}
                      color={"#141313"}
                    >
                      {data?.name}
                    </Typography>
                    <Chip
                      label={data?.is_active ? "active" : "inactive"}
                      size="small"
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#000000",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 2,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      Plan Created On
                    </Typography>
                    <Typography
                      marginTop={2}
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#516F90"}
                    >
                      {data?.created_at
                        ? dayjs(data?.created_at)?.format(`YYYY/MM/DD`)
                        : null}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      fontWeight={500}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      Last Edited On
                    </Typography>
                    <Typography
                      marginTop={2}
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#516F90"}
                    >
                      {data?.updated_at
                        ? dayjs(data?.updated_at)?.format(`YYYY/MM/DD`)
                        : null}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: " #2D3E50",
                  }}
                >
                  <b
                    style={{
                      fontWeight: 600,
                      fontSize: "24px",
                      color: " #2D3E50",
                    }}
                  >
                    &#x20b9;{data?.price}
                  </b>
                  /
                  {data?.plan_period === 30
                    ? "month"
                    : data?.plan_period === 90
                    ? "quarter"
                    : "year"}
                </p>
                <Tooltip title={data?.description}>
                  <Typography
                    marginTop={2}
                    fontWeight={400}
                    fontSize={"14px"}
                    color={"#516F90"}
                    sx={{
                      display: "inline-block", // Ensures the text behaves as an inline block
                      overflow: "hidden", // Hides the overflow text
                      textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                      whiteSpace: "nowrap", // Prevents the text from wrapping
                      verticalAlign: "bottom",
                      maxWidth: "200px",
                      cursor: "pointer",
                    }}
                  >
                    {data?.description}
                  </Typography>
                </Tooltip>
                <Divider sx={{ my: 2 }} />
                <Typography
                  fontWeight={600}
                  fontSize={"16px"}
                  color={"#2D3E50"}
                >
                  Includes:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      sx={{
                        marginRight: 1,
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      {data?.hires} hires/
                      {data?.plan_period === 30
                        ? "month"
                        : data?.plan_period === 90
                        ? "quarter"
                        : "year"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      sx={{
                        marginRight: 1,
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      {data?.job_slots} job slots
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      sx={{
                        marginRight: 1,
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      {data?.featured_job_slots} Job brand
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      sx={{
                        marginRight: 1,
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      {data?.search_results} search results
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      sx={{
                        marginRight: 1,
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      {data?.cv_views} CV views
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      sx={{
                        marginRight: 1,
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#2D3E50"}
                    >
                      {data?.job_posts} Job posts
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              {location?.state?.name && data?.is_active && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                  }}
                  onClick={() => handlePlan(data._id)}
                >
                  <Button>Subscribe</Button>
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Subscription;
